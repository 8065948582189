const PRISME_URL = process.env.NEXT_PUBLIC_PRISME_URL ?? "";

export const routes = {
  home: "/",
  resultPage: "/v2/reservation/categories",
  resultGuaranteedCat: "/v3/reservation/categories",
  account: "/account",
  checkIn: "/check-in",
  checkInWithSlug: (slug: string) => `/check-in/${slug}`,
  addDriver: "/check-in/add-driver",
  addDocuments: (slug: string) => `/check-in/add-documents/${slug}`,
  checkinConfirmation: (slug: string) => `/check-in/confirmation/${slug}`,
  cgv: "/cgv",
  paymentConfirmation: (slug: string) => `/payment/confirmation/${slug}`,
  partnerPage: (slug: string) => `/partenaires/${slug}`,
  nosDossiers: (slug?: string) => `/nos-dossiers/${slug ?? ""}`,
  groupePage: (slug: string) => `/groupe/${slug}`,
  utilesPage: (slug: string) => `/utiles/${slug}`,
  agencies: "/agences",
  additionalOptions: "/reservation/additional-option",
  agencyPage: (slug: string) => `/agences/${slug}`,
  cityPage: (slug: string) => `/agences/v/${slug}`,
  departmentPage: (slug: string) => `/agences/departement/${slug}`,
  domTomPage: (slug: string) => `/agences/dom-tom/${slug}`,
  billings: `/161425b1-044c-4ae0-9325-fa9c178875b3`,
  insurancePage: (idReservation: string, optionSelect: string) =>
    // add isIsiracId query params to force reservation creation on legacy
    // to delete when redirection is over
    `${PRISME_URL}/reservation/assurance-options?isIsiracId=true&idReservation=${idReservation}&isFlex=${optionSelect}`,
  insurancePageNoLogin: (idReservation: string, optionSelect: string) =>
    // add isIsiracId query params to force reservation creation on legacy
    // to delete when redirection is over
    `${PRISME_URL}/reservation/assurance-options/v2?isIsiracId=true&idReservation=${idReservation}&isFlex=${optionSelect}`,
  vehiclePageCar: "/vehicules-tourisme",
  vehiclePageCarCategory: (slug: string) => `/vehicules-tourisme/${slug}`,
  vehiclePageTruck: "/vehicules-utilitaires",
  vehiclePageTruckCategory: (slug: string) => `/vehicules-utilitaires/${slug}`,
  solutionPro: "/solutions-pro",
  oneWayTruck: "/aller-simple",
  oneWayCar: "/aller-simple/233-location-voiture-aller-simple",
  faq: "/faq",
  licenseFreeTruck: "/voiture-sans-permis/221-utilitaire-sans-permis",
  licenseFreeCar: "/voiture-sans-permis",
  press: "/presse",
  vtc: "/location-voiture-vtc",
  paymentPage: (slug: string) => `/payment/${slug}`,
  confirmationPage: (slug: string) => `/payment/confirmation/${slug}`,
  engagements: "/engagements",
  loyaltyProgram: "/programme-fidelite-pro",
  loyaltyProgramInscription: "/programme-fidelite-pro/inscription",
  aboutUs: "/nous-connaitre",
  joinUs: "/nous-rejoindre",
  vtcLyon: "/location-voiture-vtc-lyon",
};
