type StarIconProps = {
  size?: number;
};

export const WhiteStarIcon = ({ size = 30 }: StarIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0036 7.2371C20.206 7.23288 20.4042 7.30457 20.5518 7.43951C20.6277 7.50276 20.6867 7.58288 20.7247 7.67565C20.7626 7.7642 20.7837 7.86119 20.7795 7.9624C20.7795 8.05517 20.7584 8.14794 20.7162 8.23649C20.6783 8.32083 20.6192 8.39673 20.5518 8.45999L20.4168 8.55276L14.9307 12.4365L17.0307 18.5341C17.0982 18.6732 17.115 18.8335 17.0729 18.9853C17.0307 19.1329 16.9337 19.2636 16.803 19.3479C16.6849 19.4407 16.5415 19.504 16.3939 19.5293C16.2295 19.5124 16.0692 19.4491 15.9385 19.3479L10.5451 15.5528L5.15177 19.3479C5.02104 19.4491 4.8608 19.5124 4.69634 19.5293C4.54454 19.504 4.40538 19.4407 4.28731 19.3479C4.15659 19.2636 4.0596 19.1329 4.01743 18.9853C3.97526 18.8377 3.98791 18.6775 4.0596 18.5341L6.1596 12.4365L0.631284 8.59493C0.546947 8.54011 0.47526 8.46421 0.420441 8.37987C0.365621 8.29553 0.32767 8.19854 0.315019 8.09734V7.9624C0.302368 7.86541 0.310802 7.76421 0.34032 7.66722C0.374055 7.57445 0.424658 7.48589 0.496344 7.41421C0.568031 7.34252 0.652369 7.29192 0.749357 7.2624C0.846345 7.23288 0.947549 7.22444 1.04454 7.2371H7.80839L9.86622 1.22806C9.91261 1.08469 10.0054 0.95818 10.1277 0.869626C10.25 0.781072 10.3975 0.730469 10.5494 0.730469C10.7012 0.730469 10.8488 0.776855 10.971 0.869626C11.0933 0.95818 11.1861 1.08469 11.2325 1.22806L13.2861 7.2371H20.0036Z"
        fill="white"
      />
    </svg>
  );
};
