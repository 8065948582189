import { WhiteStarIcon } from "@/public/icons/WhiteStarIcon";
import styles from "./StarsRating.module.scss";

type StarsRatingProps = {
  rating: number | undefined;
  starSize: number;
  spacing: number;
};

const getStarStyle = (
  starIndex: number,
  starSize: number,
  rating?: number
): React.CSSProperties => {
  if (rating != null && rating <= starIndex) {
    return { backgroundColor: "#c7deff", width: starSize, height: starSize };
  }

  if (rating != null && rating >= starIndex + 1) {
    return { backgroundColor: "#0073ff", width: starSize, height: starSize };
  }

  return {
    background: `linear-gradient(to right, #0073ff 80%, #c7deff 50%)`,
    width: starSize,
    height: starSize,
  };
};

const STARS_TO_SHOW = 5;

export const StarsRating = ({
  rating,
  starSize,
  spacing,
}: StarsRatingProps) => {
  const roundedRating = rating;

  return (
    <div className={styles.ratingStarsContainer}>
      {Array.from({ length: STARS_TO_SHOW }, (_, index) => (
        <div
          key={index}
          className={styles.starContainer}
          style={{
            ...getStarStyle(index, starSize, roundedRating),
            marginRight: index !== STARS_TO_SHOW - 1 ? spacing : 0,
          }}
        >
          <WhiteStarIcon size={starSize} />
        </div>
      ))}
    </div>
  );
};
