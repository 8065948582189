export enum Feature {
  PROTECTED = "protected",
  NEW_REVIEWS_BLOCK = "newReviewsBlock",
}

const features = {
  [Feature.PROTECTED]: process.env.NEXT_PUBLIC_IS_PROTECTED_ENABLED,
  [Feature.NEW_REVIEWS_BLOCK]: process.env.NEXT_PUBLIC_IS_NEW_REVIEWS_BLOCK,
};

export const isFeatureEnabled = (featureName: Feature) =>
  features[featureName] === "true";
