import Image from "next/image";
import dayjs from "dayjs";
import Link from "next/link";
import quote from "@/public/images/quote1.png";
import { Review } from "@/interfaces/Review";
import { routes } from "@/routes/routes";
import { ReviewsIcon } from "@/public/icons/ReviewsIcon";
import { StarsRating } from "../ratingInStars/StarsRating";
import styles from "./MainReviewBlock.module.scss";

export const MainReviewBlock = ({
  text,
  date,
  author,
  agencyName,
  agencySlug,
  rating,
}: Review) => (
  <div className={styles.mainReviewContainer}>
    <StarsRating rating={rating} starSize={23.5} spacing={5.5} />
    <p className={styles.reviewText}>« {text} »</p>
    <p className={styles.reviewAuthor}>
      {`${author}. (${dayjs(date).tz("Europe/Paris").format("DD/MM/YYYY")})`}
    </p>
    <Link href={routes.agencyPage(agencySlug)}>
      <a className={styles.reviewAgency}>{agencyName}</a>
    </Link>

    <div className={styles.avisVerifiesImageWrapper}>
      <ReviewsIcon />
    </div>

    <div className={styles.quoteImageContainer}>
      <div className={styles.quoteImageWrapper}>
        <Image src={quote} alt="" layout="fill" />
      </div>
    </div>
  </div>
);
