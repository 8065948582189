import { NextPage } from "next";
import Script from "next/script";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { PageProps } from "@/pages/_app";
import { SEO } from "@/interfaces/seo";
import { SEOWithoutTemplate } from "@/interfaces/seoWithoutTemplate";
import { routes } from "@/routes/routes";
import { StrapiEntity, StrapiImage } from "@/utils/strapi";
import { PromoBanner } from "@/components/common/heroBlock/promoBanners/PromoBanners";
import Layout from "@/components/layout/Layout";
import HeroBlock from "@/components/common/heroBlock/HeroBlock";
import { LocaBestModal } from "@/components/common/locaBestModal/LocaBestModal";
import { VehicleType } from "@/components/common/searchBlock/children/vehicleTypeSelection/VehicleTypeSelection";
import { Feature, isFeatureEnabled } from "@/utils/isFeatureEnabled";
import ReviewsBlock2 from "@/components/common/reviewsBlockV1/ReviewsBlock";
import ReviewsBlock1, {
  RentACarReview,
} from "@/components/common/reviewsBlock/ReviewsBlock";
import { StructuredReviewProps } from "@/components/common/structuredData/StructuredData.interface";
import Services, { ServicesBlock } from "./services/Services";
import AgenciesNetwork, {
  AgenciesNetworkProps,
} from "./agenciesNetwork/AgenciesNetwork";
import Vehicles, { VehiclesBlock } from "./vehicles/Vehicles";
import LearnMoreBlock, {
  LearnMoreBlockProps,
} from "./learnMoreBlock/LearnMoreBlock";

const ReviewsBlock = ({
  name,
  agency,
  rentACarReview,
  reviewsConst,
  image,
}: StructuredReviewProps) => {
  if (!isFeatureEnabled(Feature.NEW_REVIEWS_BLOCK)) {
    return (
      <ReviewsBlock1
        name={name}
        agency={agency}
        rentACarReview={rentACarReview}
        reviewsConst={reviewsConst}
        image={image}
      />
    );
  } else {
    return (
      <ReviewsBlock2
        name={name}
        agency={agency}
        rentACarReview={rentACarReview}
        reviewsConst={reviewsConst}
        image={image}
      />
    );
  }
};

type HomeHeroBlock = {
  title: string;
  promoBanners: PromoBanner[];
  topHomeImage: { data: null } | StrapiEntity<StrapiImage>;
};

export type HomePageContent = {
  heroBlock: HomeHeroBlock;
  servicesBlock: ServicesBlock | undefined;
  agenciesNetwork: AgenciesNetworkProps | undefined;
  vehiclesBlock: VehiclesBlock | undefined;
  learnMoreBlock: LearnMoreBlockProps | undefined;
  rentACarReview: RentACarReview | undefined;
  seo: SEO;
  seoWithoutTemplate: SEOWithoutTemplate;
};

type HomePageProps = PageProps & {
  homePageContent: HomePageContent;
};

export const Home: NextPage<HomePageProps> = ({
  layout,
  reviewsConst,
  homePageContent,
}: HomePageProps) => {
  const router = useRouter();
  const [isLocaBestModalOpen, setIsLocaBestModalOpen] = useState(false);

  useEffect(() => {
    if (router.query.locabest === "1") {
      setIsLocaBestModalOpen(true);
    }
  }, [router.query.locabest]);

  return (
    <>
      <Layout
        layout={layout}
        pageMeta={homePageContent.seo}
        title={homePageContent.seoWithoutTemplate.title}
        description={homePageContent.seoWithoutTemplate.description}
        defaultCanonicalRoute={routes.home}
      >
        <Script
          strategy="beforeInteractive"
          id="insider-home-page"
          dangerouslySetInnerHTML={{
            __html: `
          window.insider_object = window.insider_object || {};
          window.insider_object.page = {
              "type": "Home"
          }
          `,
          }}
        />
        <HeroBlock
          {...homePageContent.heroBlock}
          hideSearchBlock={false}
          searchBlockInitialState={{
            vehicleType: VehicleType.CAR,
            isOneWayTrip: false,
          }}
        />
        {homePageContent.servicesBlock && (
          <Services {...homePageContent.servicesBlock} />
        )}
        {homePageContent.agenciesNetwork && (
          <AgenciesNetwork {...homePageContent.agenciesNetwork} />
        )}
        {homePageContent.vehiclesBlock && (
          <Vehicles {...homePageContent.vehiclesBlock} />
        )}
        {homePageContent.rentACarReview && (
          <ReviewsBlock
            rentACarReview={homePageContent.rentACarReview}
            reviewsConst={reviewsConst}
          />
        )}
        {homePageContent.learnMoreBlock && (
          <LearnMoreBlock {...homePageContent.learnMoreBlock} />
        )}
      </Layout>
      <LocaBestModal
        isOpen={isLocaBestModalOpen}
        setIsOpen={setIsLocaBestModalOpen}
      />
    </>
  );
};
