import { SSRAuthClient } from "@react-keycloak-fork/ssr";

export const BuildLogoutUrl = ({
  authServerUrl,
  realm,
  idToken,
}: SSRAuthClient) => {
  return (
    `${authServerUrl ?? ""}realms/${
      realm ?? ""
    }/protocol/openid-connect/logout` +
    `?id_token_hint=${idToken ?? ""}` +
    `&post_logout_redirect_uri=${encodeURIComponent(window.location.origin)}`
  );
};
